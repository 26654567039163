import { OneFishProvider } from "@efishery/onefish";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { memo } from 'react';

import { SERVICE } from "./configs/service";
import { AuthProvider } from "./libs/authv2";
import AppRouter from "./routes";

dayjs.extend(utc);
dayjs.extend(timezone);

const VersionDisplay = memo(() => {
  const version = process.env.NEXT_PUBLIC_APP_VERSION || 'development';
  const environment = version.includes('alpha') 
    ? 'staging'
    : version.includes('beta')
      ? 'uat'
      : 'production';

  return (
    <div 
      className={`fixed bottom-2 right-2 px-3 py-1 
        rounded-full text-xs opacity-70 hover:opacity-100 transition-opacity cursor-help
        flex items-center space-x-2`}
      title={`Environment: ${environment}`}
    >
      <span className="w-2 h-2 rounded-full bg-current animate-pulse" />
      <span>v{version}</span>
    </div>
  );
});

VersionDisplay.displayName = 'VersionDisplay';

function App() {
  return (
    <OneFishProvider>
      <AuthProvider domain={SERVICE.AUTH} redirectUri={window.location.origin}>
        <AppRouter />
        <VersionDisplay />
      </AuthProvider>
    </OneFishProvider>
  );
}

export default App;